<!-- 已匹配验收单列表 -->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'table-component',
  extends: TablePage,
  props: {
    id: String,
    type: String,
    value: Array,
  },
  data() {
    return {
      tableDataCopy: [],
    };
  },
  async created() {
    await this.getConfigList('audit_matching_acceptance_list');
  },
  watch: {
    id(val) {
      this.getList();
    },
  },
  methods: {

    beforeGetList() {
      if (this.id === 'undefined' || !this.id) {
        return false;
      }
      const url = this.type === 'cancel_matching_acceptance' ? '/kms/kmsauditform/matchedAcc' : '/kms/kmsauditform/waitMatchedAcc';
      this.requestUrl = url;
      this.params.id = this.id;
      return true;
    },

    // searchEvent() {
    //   this.getList();
    // },

    afterCheckboxChange() {
      // console.log(this.selectRow);
      this.$emit('selected', this.selectRow);
    },

    afterCheckAllChange() {
      this.$emit('selected', this.selectRow);
    },

    // searchEvent(val) {
    //   const newTableData = [];
    //   this.tableDataCopy.forEach((v) => {
    //     let status = true;
    //     if (v.orderNumber.indexOf(this.formData.orderNumber) === -1) {
    //       status = false;
    //     }
    //     if (this.formData.startDate && new Date(v.acceptanceDate) < new Date(this.formData.startDate)) {
    //       status = false;
    //     }
    //     if (this.formData.endDate && new Date(v.acceptanceDate) > new Date(this.formData.endDate)) {
    //       status = false;
    //     }
    //     if (status) {
    //       newTableData.push(v);
    //     }
    //   });
    //   this.tableData = newTableData;
    // },
  },
};
</script>
