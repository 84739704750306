<!-- 抽取SAP数据 -->
<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
      auditTemplateId: '',
    };
  },
  async created() {
    const rule = await this.getFormRule('audit_matching_get_SAP');
    this.reload(rule);
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'bsDirectSystemId') {
        // 商超选择
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.headers = { functionCode: 'audit_matching_list' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemId' };
      } else if (item.field === 'storeId') {
        // 门店选择
        item.restful = '/kms/kmstenantrydirectstore/list';
        item.headers = { functionCode: 'audit_matching_list' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'terminalName',
        };
        item.restfulParamsGetValue = { bsDirectSystemId: 'bsDirectSystemId' };
        item.optionsKey = { label: 'terminalName', value: 'terminalCode' };
      }
      return item;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...formData,
        id: this.formConfig.id,
        auditTemplateId: this.formConfig.auditTemplateId,
      };
      request.post('/kms/kmsauditform/pullSap', param, { headers: { functionCode: 'audit_matching_list' } }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
