<!-- 基本信息表格 -->
<script>
import TablePage from '@/found/components/table_page';

export default {
  name: 'table-component',
  extends: TablePage,
  props: {
    value: Array,
  },
  async created() {
    await this.getConfigList('audit_base_list');
  },
  watch: {
    value(val) {
      this.tableData = val;
    },
  },
};
</script>
