<!-- 已匹配验收单列表 -->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'table-component',
  extends: TablePage,
  props: {
    id: String,
    value: Array,
  },
  async created() {
    await this.getConfigList('audit_matching_acceptance_list_detail');
  },
  watch: {
    value(val) {
      // this.tableData = val;
    },
    id(val) {
      this.getList();
    },
  },
  methods: {
    beforeGetList() {
      if (this.id === 'undefined' || !this.id) {
        return false;
      }
      this.params.id = this.id;
      return true;
    },
  },
};
</script>
