<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('audit_matching_remark');
    this.reload(rule);
    this.setValue({
      remarks: this.formConfig.row.remarks,
    });
  },

  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...formData,
        id: this.formConfig.id,
      };
      request.post('/kms/kmsauditform/addRemark', param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
