<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import sapOrderForm from './components/sap_order_form.vue'; // SAP单号信息
import acceptanceForm from './components/acceptance_form.vue'; // 已匹配验收单列表

formCreate.component('sapOrderFormVos', sapOrderForm);
formCreate.component('acceptanceFormVos', acceptanceForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      selectedRows: [],
    };
  },
  async created() {
    const rule = await this.getFormRule('audit_cancel_matching_acceptance');
    this.reload(rule);
    if (this.formConfig.id) {
      this.disabled(true, ['storeCode', 'storeName', 'compareQuantity', 'compareAmount']);
      if (this.formConfig.type === 'cancel_matching_acceptance') {
        this.display(false, 'title3'); // 取消客户验收单匹配，显示已匹配
      } else {
        this.display(false, 'title2'); // 人工匹配验收单，显示待匹配
      }
      request.post((this.formConfig.type === 'cancel_matching_acceptance') ? '/kms/kmsauditform/query' : '/kms/kmsauditform/manualmatchQuery', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
        });
        rule.forEach((item) => {
          if (item.field === 'acceptanceFormVos') {
            Object.assign(item, {
              ...this.props,
              props: {
                id: this.formConfig.id,
                type: this.formConfig.type,
              },
              on: {
                selected: (e) => {
                  this.selectedRows = e;
                },
              },
            });
          }
        });
        this.row = res.result;
      });
    }
    this.reload(rule);
  },

  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
        acceptanceFormVos: this.selectedRows,
      };
      request.post((this.formConfig.type === 'cancel_matching_acceptance') ? ('/kms/kmsauditform/manualCancelmatch') : ('/kms/kmsauditform/manualmatch'), param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
