<script>
import request from '@/found/utils/request';
import tablePageListBase from '../../../../components/table_page_list_base';
import TablePage from './table';

export default {
  extends: tablePageListBase,
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入稽核模板名称',
      title: '稽核模板',
      restful: '/kms/kmsaudittemplate/list',
      defaultProps: {
        label: 'templateName',
        value: 'id',
      },
      headers: { functionCode: 'audit_template_permissions' },
      searchName: 'templateName',
      isAdd: true,
      // operationBtns: [
      //   {
      //     command: 'enable',
      //     btntext: '启用',
      //   },
      //   {
      //     command: 'disabled',
      //     btntext: '禁用',
      //   },
      //   {
      //     command: 'remove',
      //     btntext: '移除',
      //   },
      // ],
    };
  },
  methods: {
    modelClick({ val, row }) {
      console.log(val, 2222);
    },
    /**
     * 操作按钮
     * @params command 操作按钮的command
     * @params node 操作行的node
     */
    handleCommand({ command, node }) {
      if (command === 'enable') {
        request.post('/kms/kmstenantrydirectcustomerorg/enable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
      if (command === 'disabled') {
        request.post('/kms/kmstenantrydirectcustomerorg/disable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
      if (command === 'remove') {
        request.post('/kms/kmstenantrydirectcustomerorg/delete', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
    },
  },
};
</script>
